body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  background-color: #5f9ea0;
}

.App {
  background-color: #5f9ea0;
  max-width: 1100px;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Header */
header {
  position: relative;
  width: 80%;
  padding: 10px;
}

header .title {
  text-align: center;
  font-size: 2.5rem;
}

header #lang {
  position: absolute;
  bottom: 5px;
  right: 0;
  width: 100px;
  padding: 5px;
  border-radius: 5px;
}

/* Container */
.container {
  width: 100%;
  text-align: center;
  padding-bottom: 70px;
}

.container .words {
  background-color: #ffe4c4;
  width: 80%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px;
  margin: 0 auto;
  border: 2px solid black;
  border-radius: 5px;
  font-size: 1.3rem;
}

.container .words span {
  padding: 3px;
  margin: 2px;
  border-radius: 5px;
}

.container .input {
  width: 60%;
  padding: 5px;
  margin: 15px 5px;
  font-size: 1.3rem;
}

.container .timer {
  background-color: #ffe4c4;
  padding: 5px;
  border: 2px solid black;
  border-radius: 5px;
  font-size: 1.3rem;
}

.correct {
  color: green;
  text-shadow: green 0px 0px 1px;
}

.incorrect {
  color: red;
  text-shadow: red 0px 0px 1px;
}

.next {
  background-color: #a2d2ff;
}

/* Result */
.result {
  background-color: #ffc8dd;
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  border: 2px solid black;
  border-radius: 5px;
  font-size: 1.3rem;
}

.result p {
  margin: 3px;
}

.result .head {
  width: 100%;
}

.result .btn {
  background-color: #ffafcc;
  border-radius: 5px;
  margin: 5px auto;
  padding: 5px 15px;
  border: 1px solid black;
  font-size: 1.2rem;
}

/* Footer */
footer {
  background-color: #ffe4c4;
  text-align: center;
  padding: 0 30px;
  border-top: 2px solid black;
  border-left: 2px solid black;
  border-right: 2px solid black;
  border-radius: 5px 5px 0 0;
  position: fixed;
  bottom: 0;
}

footer a,
p {
  margin: 5px;
}

@media (max-width: 768px) {
  .result {
    width: 55%;
  }
}

@media (max-width: 500px) {
  .result {
    width: 75%;
  }
}
